<template>
  <div class="dashboard-card border border-gray-50">
    <div class="px-4 space-y-8">
      <div v-if="!questions.length" class="flex items-center justify-center">
        <span class=" text-xl font-semibold">
          There were no application questions.
        </span>
      </div>

      <div
        v-else
        v-for="question in questions"
        :key="question.id"
        class="bg-guestio-gray-100 px-4 py-4 md:px-8 md:py-6 rounded-xl"
      >
        <h4 class="text-blue-800 font-bold text-base">
          {{ question.question }}?
        </h4>

        <p class="mt-2 text-sm text-blue-800">
          {{ question.answer }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ApplicationCard",

    components: {
      //
    },

    computed: {
      booking() {
        return this.$store.getters['dashboard/bookings/booking']
      },

      questions() {
        return this.booking.questionAnswers;
      },
    },

    methods: {
      //
    },
  }
</script>
